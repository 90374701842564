
import { Vue, Options } from 'vue-class-component';
import { Patient } from '@/models';
import { BaseButton, BaseTextInput } from '@/lib/components';
import ScansPage from '@/views/image-review/ScansPage.vue';

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },
    patient: {
      type: Object,
      default: null
    }
  },
  components: { BaseButton, BaseTextInput, ScansPage }
})
export default class PatientScansPage extends Vue {
  patient!: Patient | null;
  search = '';
  refreshScans = false;

  loaded() {
    this.refreshScans = false;
  }
}
