import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex justify-between pb-8 border-b" }
const _hoisted_4 = { class: "flex items-center text-lg font-bold text-gray-900" }
const _hoisted_5 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ScansPage = _resolveComponent("ScansPage")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('platform.patient.patient-scans')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BaseButton, {
            "left-icon": "reload",
            color: "ghost",
            disabled: _ctx.refreshScans,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshScans = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.refresh')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    (_ctx.patient)
      ? (_openBlock(), _createBlock(_component_ScansPage, {
          key: 0,
          "patient-id": _ctx.patientId,
          patient: _ctx.patient,
          readonly: "",
          "refresh-scans": _ctx.refreshScans,
          onLoaded: _ctx.loaded
        }, null, 8, ["patient-id", "patient", "refresh-scans", "onLoaded"]))
      : _createCommentVNode("", true)
  ]))
}